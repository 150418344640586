/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: 'Technical Project Manager',
  introTitle: 'Technical Project Manager (or Technical Project Lead) – full-time software development',
  introInfo: '<p>freshcells systems engineering GmbH is a software agency based in Düsseldorf with a dynamic team of over 40 employees.</p><p>We do not have a hire-and-fire mentality; we prefer to kick back with cold drinks after work.</p> <p>Among other vacancies, we are looking for a Technical Project Manager (full-time software development) to support our team in Düsseldorf.</p> <p>Are you a developer who has outgrown programming and feels destined to coordinate projects rather than doing the coding work?</p><p>Our Technical Project Managers are an important link between the project team and the stakeholders. With their sound technological background, they supervise large-scale projects and support internal processes by assisting the entire project team with technical questions.</p>',
  responsibilitiesTitle: 'Your Responsibilities',
  responsibility1: 'Independent technical support of customer projects in the web environment',
  responsibility2: 'Technical preparation of customer requirements for the developers',
  responsibility3: 'Systematic analysis of problem areas and development of solutions',
  responsibility4: 'Participation in the requirements determination of initial projects and in the creation of effort forecasts',
  responsibility5: 'Quality assurance',
  responsibility6: 'Supporting the project managers in the coordination of the project, including the assignment of tasks to the development teams',
  qualificationsTitle: 'Your Qualifications',
  qualification1: 'Sound technical knowledge in modern web technology stacks and OOP languages',
  qualification2: 'Project management experience is a plus',
  qualification3: 'Software Architecture experience',
  qualification4: 'Knowledge of ReST, SOAP and ideally graphQL API protocols',
  qualification5: 'Fluent in English',
  qualification6: 'Flexibility and initiative',
  qualification7: 'Analytical and entrepreneurial thinking',
  qualification8: 'Motivation and a love of challenges',
  qualification9: 'Team player with good communication skills',
  nicetohaveTitle: 'Nice to have',
  nicetohave1: 'Knowledge of designing and adapting software to be twelve factor applications',
  nicetohave2: 'Knowledge of continuous integration via tools like Jenkins',
  nicetohave3: 'Experience with containerization technologies like Docker, Vagrant, etc.',
  nicetohave4: 'Experience with container orchestration tools like Kubernetes',
  nicetohave5: 'German language skills',
  nicetohave6: 'Cloud Architecture experience'
})
